<template>
  <div class="d-flex align-items-center justify-content-center login-wrapper">
    <div class="p-3 bg-light border rounded">
      <div class="login-form text-center">
        <h4 class="text-uppercase">Đăng nhập Admin</h4>
        <input
          v-model="account"
          type="text"
          class="form-control mb-3"
          placeholder="Tài khoản"
        />
        <input
          v-model="password"
          type="password"
          class="form-control mb-3"
          placeholder="Mật khẩu"
        />
        <button @click="login" class="btn btn-primary w-100">Đăng nhập</button>
      </div>
    </div>
  </div>
</template>

<script>
import service from "../services";
export default {
  name: "Login",
  data() {
    return {
      account: null,
      password: null,
    };
  },
  methods: {
    async login() {
      let res = await service.post("/admins/login", {
        account: this.account,
        password: this.password,
        tenantId: "64246e93cc817911f210e1af",
      });
      if (res.status == 200) {
        localStorage.setItem("token", res.data.token);
        this.$router.push("/");
      } else {
        this.$toast.error(res.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 360px;
}
.login-wrapper {
  height: 100vh;
}
</style>
