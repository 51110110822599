<template>
  <div>
    <button @click="exportData" class="btn btn-primary my-3">Xuất khẩu</button>
    <p class="text-info">
      Danh sách chỉ hiển thị tối đa 15 bản ghi gần nhất, xuất khẩu để tài về
      file đầy đủ bản ghi!
    </p>
    <!-- TABLE -->
    <table v-if="students" class="table table-striped table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Họ và tên</th>
          <th scope="col">Số CMND/CCCD</th>
          <th scope="col">Số điện thoại</th>
          <th scope="col">Đối tượng</th>
          <th scope="col">Đơn vị</th>
          <th scope="col">TT bổ sung</th>
          <th scope="col">Ngày, tháng, năm sinh</th>
          <th scope="col">Địa chỉ</th>
          <th scope="col">Địa chỉ email</th>
          <th scope="col">Số câu đúng</th>
          <th scope="col">Số dự đoán</th>
          <th scope="col">Thời gian bắt đầu</th>
          <th scope="col">Thời gian kết thúc</th>
          <th scope="col">Tổng thời gian(s)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, index) in students" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ student.name }}</td>
          <td>{{ student.identityNumber }}</td>
          <td>{{ student.phone }}</td>
          <td>{{ student.objectType }}</td>
          <td>{{ student.organization }}</td>
          <td>{{ student.extraInfor }}</td>
          <td>{{ student.dateOfBirth }}</td>
          <td>{{ student.address }}</td>
          <td>{{ student.email }}</td>
          <td>{{ student.totalPoint }}</td>
          <td>{{ student.guessTruePerson }}</td>
          <td>{{ student.timeStart | parseTime }}</td>
          <td>{{ student.timeEnd | parseTime }}</td>
          <td>{{ student.totalTime }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import service from "../services";
import moment from "moment";
export default {
  name: "UserExamStudent",
  props: {
    examId: String,
  },
  data() {
    return {
      students: null,
    };
  },
  filters: {
    parseTime(value){
      let result = moment(value).format("HH:mm:ss DD/MM/YYYY")
      return result;
    }
  },
  methods: {
    async fetchData() {
      let res = await service.get(
        "/user-exams/exams/" + this.examId + "/student"
      );
      if (res.status == 200) {
        this.students = res.data;
      }
    },
    async exportData() {
      console.log(123);
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        with: 36,
        height: 36,
      });
      setTimeout(() => {
        console.log(12);
      }, 5000);
      const response = await service({
        url: `/exams/export/${this.examId}/2`, //your url
        method: "GET",
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ket_qua_bai_thi.xlsx"); //or any other extension
      document.body.appendChild(link);
      setTimeout(() => {
        loader.hide();
      }, 5000);
      link.click();
      loader.hide();
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style></style>
