<template>
  <div class="mt-5 p-3">
    <h4>Bài thi đang hoạt động</h4>
    <div v-if="examActive" class="rounded bg-light mb-3 p-3 border">
      <div>
        <h5 class="text-uppercase">{{ examActive.name }}</h5>
        <p>
          Số câu hỏi sẽ hiển thị:
          <span
            ><i>{{ examActive.totalRandomQuestion }}</i></span
          >
        </p>
        <p>
          Thời gian bắt đầu:
          <span
            ><i>{{ new Date(examActive.timeOpen).toLocaleString() }}</i></span
          >
        </p>
        <p>
          Thời gian kết thúc:
          <span
            ><i>{{ new Date(examActive.timeClose).toLocaleString() }}</i></span
          >
        </p>
      </div>
      <router-link :to="`question-exam/${examActive._id}`">Danh sách câu hỏi</router-link>
      /
      <router-link :to="`user-exam/${examActive._id}`">Danh sách người tham gia</router-link>
    </div>

    <h4>
      Tạo bài thi khác
      <i class="text-warning">(Bài thi này sẽ ghi đè bài thi cũ)</i>
    </h4>
    <button class="btn btn-primary" @click="downloadTemplate">Tải về file mẫu nhập khẩu</button>

    <div class="bg-light mt-3 p-3 rounded border">
      <div class="mb-3">
        <label for="formFile" class="form-label"
          >Tải lên file dữ liệu câu hỏi <i class="text-danger">(Định dạng XLSX)</i></label
        >
        <input class="form-control" type="file" id="formFile" />
      </div>

      <div class="mb-3">
        <label for="">Tên bài thi</label>
        <input v-model="name" type="text" class="form-control" />
      </div>

      <div class="mb-3">
        <label for="">Số câu hỏi Random hiển thị</label>
        <input v-model="totalRandomQuestion" type="number" class="form-control" />
      </div>

      <div class="mb-3">
        <label for="">Đơn vị (Các đơn vị phân cách nhau bằng dấu ",")</label>
        <textarea rows="5" v-model="organizations" type="number" class="form-control" />
      </div>

      <div class="mb-3">
        <label for="">Đối tượng (Các đối tượng phân cách nhau bằng dấu ",")</label>
        <textarea  rows="5" v-model="objectives" type="number" class="form-control" />
      </div>

      <div class="mb-3">
        <label for="">Thời gian mở/đóng</label> <br />
        <DatePicker v-model="time" type="datetime" range />
      </div>
      <button class="btn btn-primary" @click="createExam">Tạo</button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import service from "../services";
export default {
  name: "Exam",
  data() {
    return {
      file: null,
      name: "",
      time: null,
      examActive: null,
      totalRandomQuestion: 10,
      organizations: "",
      objectives: "",
    };
  },
  components: {
    DatePicker,
  },
  methods: {
    async fetchData() {
      let res = await service.get("/exams/active");
      if (res.status == 200) {
        this.examActive = res.data.exam;
      }
    },
    async createExam() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        with: 36,
        height: 36,
      });

      let formFile = document.getElementById("formFile");
      if (!formFile?.files[0] || !this.name || !this.time || !this.totalRandomQuestion) {
        alert("Vui lòng nhập đầy đủ thông tin");
        return;
      }
      let formData = new FormData();
      formData.append("exam", formFile.files[0]);
      formData.append("name", this.name);
      formData.append("totalRandomQuestion", this.totalRandomQuestion);
      formData.append("timeOpen", this.time[0]);
      formData.append("timeClose", this.time[1]);
      formData.append("organizations", this.organizations);
      formData.append("objectives", this.objectives);
      await service.post("/exams/import", formData);
      this.$toast.success("Tạo bài thi thành công");
      await this.fetchData();
      loader.hide();
    },
    async downloadTemplate() {
      window.open(window.apiUrl + "/exams/download/template", "_blank");
    },
  },

  async created() {
    await this.fetchData();
  },
};
</script>

<style></style>
