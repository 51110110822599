<template>
  <div class="p-3 mt-5">
    <h4>Thiết lập Banner</h4>
    <div class="p-3 bg-light rounded border">
      <div class="mb-3">
        <label for="new-banner" class="form-label">Tải lên banner mới <i class="text-danger">[jpeg/png] 1440x480</i></label>
        <input
          @change="uploadFileOnChange"
          accept="image/*"
          class="form-control"
          type="file"
          id="new-banner"
        />
        <button @click="saveData" class="btn btn-primary mt-3">Lưu lại</button>
        <div class="mt-3">
          <img
            v-if="previewSrc"
            class="img-fluid rounded"
            id="preview"
            :src="previewSrc"
            alt="Banner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "../services";
export default {
  name: "Banner",
  data() {
    return {
      file: null,
      previewSrc: null,
    };
  },
  methods: {
    async fetchData() {
      let res = await service.get("/directories");
      if (res.status == 200) {
        this.previewSrc = window.apiUrl.replace(
          "api",
          res.data.bannerUrl.replace("../..", "")
        );
      }
    },
    async saveData() {
      if (this.file == null) {
        return;
      }
      let formData = new FormData();
      formData.append("banner", this.file);
      let res = await service.post("/exams/upload/banner", formData);
      if (res.status == 200) {
        this.$toast.success("Lưu thành công");
      }
    },
    uploadFileOnChange(e) {
      try {
        this.file = e.target.files[0];
        this.previewSrc = URL.createObjectURL(e.target.files[0]);
      } catch (error) {
        this.file = null;
        this.previewSrc = null;
      }
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style></style>
