<template>
  <div>
    <!-- TABLE -->
    <table v-if="questions" class="table table-striped table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nội dung</th>
          <th scope="col">A</th>
          <th scope="col">B</th>
          <th scope="col">C</th>
          <th scope="col">D</th>
          <th scope="col">Đáp án đúng</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(question, index) in questions" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ question.content }}</td>
          <td>{{ getValue(question.answers, 0) }}</td>
          <td>{{ getValue(question.answers, 1) }}</td>
          <td>{{ getValue(question.answers, 2) }}</td>
          <td>{{ getValue(question.answers, 3) }}</td>
          <td>{{ question.trueAnswer }}</td>
          <!-- <td>{{ student.identityNumber }}</td>
          <td>{{ student.phone }}</td>
          <td>{{ student.objectType }}</td>
          <td>{{ student.organization }}</td>
          <td>{{ student.extraInfor }}</td>
          <td>{{ student.totalPoint }}</td>
          <td>{{ student.guessTruePerson }}</td>
          <td>{{ student.totalTime }}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "QuestionExamAll",
  props: {
    questions: Array,
  },
  methods: {
    getValue(_v, index){
      if (!_v) {
        return ""
      }else{
        return _v[index].name
      }
    }
  }
};
</script>

<style></style>
