<template>
  <div class="mt-5 p-3">
    <h4>Danh sách câu hỏi</h4>
    <!-- <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          :class="[activeTab == 'QuestionExamStudent' ? 'active' : '']"
          class="nav-link"
          @click="changeTab('QuestionExamStudent')"
          >Học sinh</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="[activeTab == 'QuestionExamTeacher' ? 'active' : '']"
          class="nav-link"
          @click="changeTab('QuestionExamTeacher')"
          >Cán bộ và nhân dân</a
        >
      </li>
    </ul> -->
    <QuestionExamAll :questions="questions" />
  </div>
</template>

<script>
import QuestionExamAll from "../components/QuestionExamAll";
import service from "../services";
export default {
  name: "QuestionExam",
  data() {
    return {
      exam: null,
      activeTab: "QuestionExamStudent",
      questions: [],
    };
  },
  components: {
    QuestionExamAll,
  },
  methods: {
    async fetchData() {
      let res = await service.get("/exams/active");
      if (res.status == 200) {
        this.exam = res.data.exam;
        // if (this.activeTab == "QuestionExamStudent") {
        //   this.questions = this.exam.questionStudents;
        // } else {
          this.questions = this.exam.questionTeachers;
        // }
      }
    },
    changeTab(name) {
      this.activeTab = name;
      if (this.activeTab == "QuestionExamStudent") {
        this.questions = this.exam.questionStudents;
      } else {
        this.questions = this.exam.questionTeachers;
      }
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style></style>
