<template>
  <div class="p-3 mt-5">
      <h4>Thiết lập chung</h4>
      <div v-if="directories" class="p-3 bg-light rounded border">
          <label for="">Tên WebSite</label>
          <input v-model="directories.webName" type="text" class="form-control mb-3">

          <label for="">Nội dung tiêu đề lớn</label>
          <input v-model="directories.mainTitle" type="text" class="form-control mb-3">

          <label for="">Nội dung tiêu đề nhỏ</label>
          <input v-model="directories.subTitle" type="text" class="form-control mb-3">
          
          <label for="">Thể lệ cuộc thi</label>
          <textarea v-model="directories.rule" rows="10" type="text" class="form-control mb-3"></textarea>

          <label for="">Kết quả cuộc thi</label>
          <textarea v-model="directories.result" rows="10" type="text" class="form-control mb-3"></textarea>

          <label for="">Chân trang</label>
          <textarea v-model="directories.footer" rows="10" type="text" class="form-control mb-3"></textarea>

          <button @click="saveData" class="btn btn-primary">Lưu lại</button>
      </div>
  </div>
</template>

<script>
import service from "../services"
export default {
  name: "Setting",
  data(){
      return {
          directories: null
      }
  },
  methods: {
      async fetchData(){
          let res = await service.get('/directories');
          if (res.status == 200) {
              this.directories = res.data;
          }
      },
      async saveData(){
          let res = await service.post('/directories', this.directories);
          if (res.status == 200) {
              this.$toast.success('Lưu thành công');
          }
      }
  },
  async created(){
      await this.fetchData();
  }
};
</script>

<style></style>
