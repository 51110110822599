<template>
  <div>
    <button @click="exportData" class="btn btn-primary my-3">Xuất khẩu</button>
    <!-- TABLE -->
    <table v-if="students" class="table table-striped table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Họ và tên</th>
          <th scope="col">Số CMND/CCCD</th>
          <th scope="col">Số điện thoại</th>
          <th scope="col">Đối tượng</th>
          <th scope="col">Đơn vị</th>
          <th scope="col">TT bổ sung</th>
          <th scope="col">Ngày, tháng, năm sinh</th>
          <th scope="col">Địa chỉ</th>
          <th scope="col">Địa chỉ email</th>
          <th scope="col">Số câu đúng</th>
          <th scope="col">Số dự đoán</th>
          <th scope="col">Tổng thời gian(s)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, index) in students" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ student.name }}</td>
          <td>{{ student.identityNumber }}</td>
          <td>{{ student.phone }}</td>
          <td>{{ student.objectType }}</td>
          <td>{{ student.organization }}</td>
          <td>{{ student.extraInfor }}</td>
          <td>{{ student.dateOfBirth }}</td>
          <td>{{ student.address }}</td>
          <td>{{ student.email }}</td>
          <td>{{ student.totalPoint }}</td>
          <td>{{ student.guessTruePerson }}</td>
          <td>{{ student.totalTime }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import service from "../services";
export default {
  name: "UserExamTeacher",
  props: {
    examId: String,
  },
  data() {
    return {
      students: null,
    };
  },
  methods: {
    async fetchData() {
      let res = await service.get(
        "/user-exams/exams/" + this.examId + "/teacher"
      );
      if (res.status == 200) {
        this.students = res.data;
      }
    },
    async exportData() {
      service({
        url: `/exams/export/${this.examId}/1`, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ket_qua_bai_thi_can_bo_va_nhan_dan.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style></style>
