<template>
  <div class="p-3 mt-5">
    <h4>Thiết lập tài khoản</h4>
    <div class="p-3 bg-light rounded border">
      <div class="mb-3">
        <h5 class="text-primary">Đổi mật khẩu</h5>
        <div class="mb-3">
          <label for="oldPassword">Mật khẩu cũ</label>
          <input
            v-model="oldPassword"
            name="oldPassword"
            type="password"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label for="newPassword">Mật khẩu mới</label>
          <input
            v-model="newPassword"
            name="newPassword"
            type="password"
            class="form-control"
          />
        </div>
        <button @click="saveChange" class="btn btn-primary">Lưu</button>
      </div>
    </div>
  </div>
</template>

<script>
import service from "../services";
export default {
  name: "Account",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
    };
  },
  methods: {
    validate() {
      if (this.oldPassword == "" || this.newPassword == "") {
        return false;
      } else {
        return true;
      }
    },

    async saveChange() {
      if (!this.validate()) {
        alert("Vui lòng nhập đầy đủ thông tin");
        return;
      }
      const res = await service.post("/admins/change-pass", {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      });
      if (res.status == 200) {
        this.$toast.success("Đổi mật khẩu thành công");
      }
      this.resetForm();
    },

    resetForm() {
      this.oldPassword = "";
      this.newPassword = "";
    },
  },
};
</script>

<style></style>
