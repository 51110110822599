<template>
  <div class="home">
    <Navbar/>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  name: "Home",
  components: {
    Navbar
  },
};
</script>
