<template>
  <div class="mt-5 p-3">
    <h4>Danh sách người tham gia bài thi</h4>
    <!-- <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          :class="[activeTab == 'UserExamStudent' ? 'active' : '']"
          class="nav-link"
          @click="changeTab('UserExamStudent')"
          >Học sinh</a
        >
      </li>
      <li class="nav-item">
        <a
          :class="[activeTab == 'UserExamTeacher' ? 'active' : '']"
          class="nav-link"
          @click="changeTab('UserExamTeacher')"
          >Cán bộ và nhân dân</a
        >
      </li>
    </ul> -->
    <component :is="activeTab" :examId="this.$route.params.id"></component>
  </div>
</template>

<script>
import UserExamStudent from "../components/UserExamStudent";
import UserExamTeacher from "../components/UserExamTeacher";
export default {
  name: "UserExam",
  data() {
    return {
      userExams: null,
      activeTab: "UserExamStudent",
    };
  },
  components: {
    UserExamStudent,
    UserExamTeacher,
  },
  methods: {
    changeTab(name) {
      this.activeTab = name;
    }
  }
};
</script>

<style></style>
