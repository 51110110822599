import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Setting from "../views/Setting.vue";
import Exam from "../views/Exam.vue";
import Login from "../views/Login.vue";
import UserExam from "../views/UserExam.vue";
import Banner from "../views/Banner.vue";
import ExamQuestion from "../views/ExamQuestion.vue";
import Account from "../views/Account.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      { path: "", component: Setting },
      {
        path: "exam",
        name: "Exam",
        component: Exam,
      },
      {
        path: "banner",
        name: "Banner",
        component: Banner,
      },
      {
        path: "account",
        name: "Account",
        component: Account,
      },
      {
        path: "/user-exam/:id",
        name: "UserExam",
        component: UserExam,
      },
      {
        path: "/question-exam/:id",
        name: "QuestionExam",
        component: ExamQuestion,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem("token");
  let isAuthenticated = true;
  if (!token) {
    isAuthenticated = false;
  }
  if (
    // make sure the user is authenticated
    !isAuthenticated &&
    // ❗️ Avoid an infinite redirect
    to.name !== "Login"
  ) {
    // redirect the user to the login page
    next({ name: "Login" });
  }
  next();
});

export default router;
