import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.config.productionTip = false
const options = {
  timeout: 1000
};
Vue.use(Toast, options);
Vue.use(Loading);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
