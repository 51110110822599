<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">NLQLADMIN</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/"
              >Thiết lập chung</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/exam">Bài thi</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/banner">Banner</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/account">Tài khoản</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="logout" href="/">Đăng xuất</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
    name: "Navbar",
    methods: {
      logout(){
        window.localStorage.clear();
        window.location.href = "/";
      }
    }
};
</script>

<style></style>
